import { api } from '@/api';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { ScansState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import {
  commitClearScans,
  commitSetFilters,
  commitRemoveFilter,
  commitAddFilter,
  commitSetVersions,
  commitSetScans,
  commitClearFilters,
  commitSetScanStats,
} from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { IFilterItem } from '@/interfaces';
import { AxiosError } from 'axios';

type MainContext = ActionContext<ScansState, State>;

export const actions = {
  actionClearScans(context: MainContext) {
    commitClearScans(context);
  },
  async actionGetScans(
    context: MainContext,
    payload: {
      appId: number;
      filters: IFilterItem[];
      skip: number;
      limit: number;
      sort: string;
      desc: boolean;
    },
  ) {
    try {
      const response = await api.getScans(
        context.rootState.main.token,
        payload.appId,
        payload.filters,
        payload.skip,
        payload.limit,
        payload.sort,
        payload.desc,
      );
      if (response) {
        commitSetScans(context, {
          data: response.data,
          append: payload.skip !== 0,
        });
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  actionClearFilters(context: MainContext) {
    commitClearFilters(context);
  },
  async actionSetFilters(
    context: MainContext,
    payload: { data: IFilterItem[] },
  ) {
    commitSetFilters(context, { data: payload.data });
  },
  async actionAddFilter(context: MainContext, payload: { data: IFilterItem }) {
    commitAddFilter(context, { data: payload.data });
  },
  async actionRemoveFilter(
    context: MainContext,
    payload: { data: IFilterItem },
  ) {
    commitRemoveFilter(context, { data: payload.data });
  },
  async actionGetVersions(context: MainContext, appId: number) {
    try {
      const response = await api.getVersions(
        context.rootState.main.token,
        appId,
      );
      if (response) {
        commitSetVersions(context, { data: response.data });
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionGetScanStats(
    context: MainContext,
    payload: { appId: number; filters: IFilterItem[] },
  ) {
    try {
      const response = await api.getScanStats(
        context.rootState.main.token,
        payload.appId,
        payload.filters,
      );
      if (response) {
        commitSetScanStats(context, { data: response.data });
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
};

const { dispatch } = getStoreAccessors<ScansState, State>('');

export const dispatchClearScans = dispatch(actions.actionClearScans);
export const dispatchGetScans = dispatch(actions.actionGetScans);
export const dispatchClearFilters = dispatch(actions.actionClearFilters);
export const dispatchSetFilters = dispatch(actions.actionSetFilters);
export const dispatchAddFilter = dispatch(actions.actionAddFilter);
export const dispatchRemoveFilter = dispatch(actions.actionRemoveFilter);
export const dispatchGetVersions = dispatch(actions.actionGetVersions);
export const dispatchGetScanStats = dispatch(actions.actionGetScanStats);
