import { api } from '@/api';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { ProductsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetProducts } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { AxiosError } from 'axios';

type MainContext = ActionContext<ProductsState, State>;

export const actions = {
  async actionGetProducts(context: MainContext, appId: number) {
    try {
      const response = await api.getProducts(
        context.rootState.main.token,
        appId,
      );
      if (response) {
        commitSetProducts(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
};

const { dispatch } = getStoreAccessors<ProductsState, State>('');

export const dispatchGetProducts = dispatch(actions.actionGetProducts);
