import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { TargetsState } from './state';

const defaultState: TargetsState = {
  targets: [],
};

export const targetsModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
