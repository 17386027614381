import { ProductsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    products: (state: ProductsState) => state.products,
    product: (state: ProductsState) => (targetId: number) => {
        const items = state.products.filter((product) => product.id === targetId);
        if (items.length > 0) {
            return { ...items[0] };
        }
    },
};

const { read } = getStoreAccessors<ProductsState, State>('');

export const readProducts = read(getters.products);
export const readProduct = read(getters.product);
