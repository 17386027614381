import { ConsumersState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
  consumers: (state: ConsumersState) => state.consumers,
  consumer: (state: ConsumersState) => (id: number) => {
    const items = state.consumers.filter((consumer) => consumer.id === id);
    if (items.length > 0) {
      return { ...items[0] };
    }
  },
  consumerStats: (state: ConsumersState) => state.stats,
};

const { read } = getStoreAccessors<ConsumersState, State>('');

export const readConsumers = read(getters.consumers);
export const readOneConsumer = read(getters.consumer);
export const readConsumerStats = read(getters.consumerStats);
