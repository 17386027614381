import { api } from '@/api';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { TargetsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetTargets } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { AxiosError } from 'axios';

type MainContext = ActionContext<TargetsState, State>;

export const actions = {
  async actionGetTargets(context: MainContext, appId: number) {
    try {
      const response = await api.getTargets(
        context.rootState.main.token,
        appId,
      );
      if (response) {
        commitSetTargets(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
};

const { dispatch } = getStoreAccessors<TargetsState, State>('');

export const dispatchGetTargets = dispatch(actions.actionGetTargets);
