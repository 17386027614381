import { AppsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
  myApps: (state: AppsState) => state.apps,
  myApp: (state: AppsState) => (id: number) => {
    const filteredApps = state.apps.filter((app) => app.id === id);
    if (filteredApps.length > 0) {
      return { ...filteredApps[0] };
    }
  },
};

const { read } = getStoreAccessors<AppsState, State>('');

export const readMyApps = read(getters.myApps);
export const readMyOneApp = read(getters.myApp);
