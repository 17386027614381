import { TargetsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    targets: (state: TargetsState) => state.targets,
    target: (state: TargetsState) => (targetId: number) => {
        const items = state.targets.filter((target) => target.id === targetId);
        if (items.length > 0) {
            return { ...items[0] };
        }
    },
};

const { read } = getStoreAccessors<TargetsState, State>('');

export const readTargets = read(getters.targets);
export const readTarget = read(getters.target);
