import { ScansState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
  scans: (state: ScansState) => state.scans,
  scan: (state: ScansState) => (scanId: number) => {
    const items = state.scans.filter((scan) => scan.id === scanId);
    if (items.length > 0) {
      return { ...items[0] };
    }
  },
  filters: (state: ScansState) => state.filters,
  versions: (state: ScansState) => state.versions,
  scanStats: (state: ScansState) => state.stats,
};

const { read } = getStoreAccessors<ScansState, State>('');

export const readScans = read(getters.scans);
export const readScan = read(getters.scan);
export const readFilters = read(getters.filters);
export const readVersions = read(getters.versions);
export const readScanStats = read(getters.scanStats);
