// Import Component hooks before component definitions
import './component-hooks';
import Vue from 'vue';
import './plugins/vee-validate';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from '@/store';
import './registerServiceWorker';
import 'vuetify/dist/vuetify.min.css';
import * as VueGoogleMaps from 'vue2-google-maps';
import GmapCluster from 'vue2-google-maps/src/components/cluster';
import './plugins/echarts';
import { googleKey } from './env';

Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.use(VueGoogleMaps, {
  load: {
    key: googleKey,
  },
});


Vue.component('GmapCluster', GmapCluster);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
