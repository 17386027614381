import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { ProductsState } from './state';

const defaultState: ProductsState = {
  products: [],
};

export const productsModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
