import { IConsumer, IConsumerStats } from '@/interfaces';
import { ConsumersState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
  setConsumers(
    state: ConsumersState,
    payload: { data: IConsumer[]; append: boolean },
  ) {
    state.consumers = payload.append
      ? state.consumers.concat(payload.data)
      : payload.data;
  },
  setConsumer(state: ConsumersState, payload: IConsumer) {
    const consumers = state.consumers.filter(
      (consumer: IConsumer) => consumer.id !== payload.id,
    );
    consumers.push(payload);
    state.consumers = consumers;
  },
  setConsumerStats(state: ConsumersState, payload: { data: IConsumerStats[] }) {
    state.stats = payload.data;
  },
};

const { commit } = getStoreAccessors<ConsumersState, State>('');

export const commitSetConsumers = commit(mutations.setConsumers);
export const commitSetConsumer = commit(mutations.setConsumer);
export const commitSetConsumerStats = commit(mutations.setConsumerStats);
