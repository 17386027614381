import { ITarget } from '@/interfaces';
import { TargetsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setTargets(state: TargetsState, payload: ITarget[]) {
        state.targets = payload;
    },
};

const { commit } = getStoreAccessors<TargetsState, State>('');

export const commitSetTargets = commit(mutations.setTargets);
