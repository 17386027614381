import { IProduct } from '@/interfaces';
import { ProductsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setProducts(state: ProductsState, payload: IProduct[]) {
        state.products = payload;
    },
};

const { commit } = getStoreAccessors<ProductsState, State>('');

export const commitSetProducts = commit(mutations.setProducts);
